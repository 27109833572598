<template>
	<screen-dialog v-model="showODB" noLogo>
		<v-container fluid fill-height>
			<img :src="imageSrc" height="100%" width="100%" class="forceFill" />
		</v-container>
	</screen-dialog>
</template>

<script>
import ScreenDialog from '../ScreenDialog.vue';
export default {
	components: { ScreenDialog },
	data() {
		return {
			image: '',
			showODB: false,
			interval: null,
		};
	},
	computed: {
		imageSrc() {
			//Add timestamp to URL to prevent caching
			if (this.showODB)
				return `${this.image}?t=${encodeURI(new Date().toUTCString())}`;
			else return `${this.image}?t1=${encodeURI(new Date().toUTCString())}`;
		},
	},
	mounted() {
		window.addEventListener('keydown', this.keyboardShortcuts);
		this.image = this.$store.state.shoot.Club.ScoreboardSettings.cameraURL;
		//Show ODB for 30 seconds every three minutes
		this.interval = setInterval(() => {
			this.showODB = true;
			setTimeout(() => {
				this.showODB = false;
			}, 30 * 1000);
		}, 3 * 60 * 1000);
	},
	beforeDestroy() {
		clearInterval(this.interval);
		window.removeEventListener('keydown', this.keyboardShortcuts);
	},
	methods: {
		keyboardShortcuts(key) {
			if (window.enable_debug.keystrokes) console.log(key.key, key.keyCode);
			switch (key.key) {
				case 'o':
				case 'O':
					this.showODB = !this.showODB;
					break;
			}
		},
	},
};
</script>

<style scoped>
.forceFill {
	object-fit: fill;
}
</style>
