<template>
	<v-row justify="center" style="overflow-y: hidden;">
		<v-dialog
			v-model="show"
			fullscreen
			hide-overlay
			transition="dialog-bottom-transition"
		>
			<v-card height="50%">
				<v-toolbar
					v-if="!noLogo"
					elevation="0"
					height="130px"
					:color="$vuetify.theme.dark ? '#1E1E1E' : '#fff'"
				>
					<v-img
						contain
						class="mx-auto"
						:src="
							$vuetify.theme.dark
								? '/images/shootpro_logo_min.png'
								: '/images/shootpro_logo_dark.png'
						"
						height="100px"
					/>
				</v-toolbar>

				<!-- @slot This is where content is rendered -->
				<slot></slot>
			</v-card>
		</v-dialog>
	</v-row>
</template>

<script>
export default {
	props: {
		/**
		 * Controls if the dialog is visible
		 */
		dialog: {
			default: false,
		},

		value: {
			default: false,
		},
		noLogo: {
			default: false,
			type: Boolean,
		},
	},
	computed: {
		show: function () {
			return this.dialog || this.value;
		},
	},
	methods: {
		CloseModal() {
			this.$emit('Close');
			this.$emit('input', false);
		},
	},
};
</script>

<style scoped>
html {
	overflow-y: hidden;
	overflow-x: hidden;
}
</style>
