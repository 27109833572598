<template>
	<screen-dialog v-model="show" noLogo>
		<v-container fluid>
			<v-row align="center">
				<v-col class="mx-12">
					<h1 class="text-center text-h2 yellow--text">
						PENDING YARDAGE PUNCHES
					</h1>
					<v-divider class="my-2" />

					<v-row class="mt-2">
						<v-col>
							<v-simple-table
								fixed-header
								height="83vh"
								id="punchTable"
							>
								<template v-slot:default>
									<thead>
										<tr>
											<th class="text-center text-h4">
												Name
											</th>
											<th class="text-center text-h4">
												Punch
											</th>
											<th class="text-center text-h4">
												Old Ydg
											</th>
											<th class="text-center text-h4">
												New Ydg
											</th>
										</tr>
									</thead>
									<tbody id="punchTable">
										<tr
											v-for="(item, idx) in sortedAnnouncementsByName"
											:ref="`row${idx}`"
											:key="item.name"
											:id="`row${idx}`"
										>
											<td class="text-h3 py-2">
												<span v-if="Array.isArray(item.linkedshooters)">
													{{ item.linkedshooters[0] }}
												</span>
												<span v-else>
													{{ item.linkedshooters }}
												</span>
											</td>
											<td class="text-center text-h3">
												{{ item.meta.punchAmt | halfYards }}
											</td>
											<td class="text-center text-h3">
												{{ item.meta.currentYdg | halfYards }}
											</td>
											<td class="text-center text-h3">
												{{ item.meta.newYdg | halfYards }}
											</td>
										</tr>
									</tbody>
								</template>
							</v-simple-table>
						</v-col>
					</v-row>
				</v-col>
			</v-row>
		</v-container>
	</screen-dialog>
</template>

<script>
import ScreenDialog from './ScreenDialog.vue';
export default {
	filters: {
		halfYards(v) {
			return `${v}`.replace(/^0\./, '.').replace('.5', '½');
		},
	},
	components: { ScreenDialog },
	props: {
		value: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			image: '',
			show: false,
			interval: null,
			rowHeight: 0,
		};
	},
	computed: {
		sortedAnnouncementsByName() {
			const ar = JSON.parse(JSON.stringify(this.$store.state.yardagePunches));
			ar.sort((a, b) => {
				// Convert both first elements to lowercase for case-insensitive sorting
				const stringA = a.linkedshooters[0].toLowerCase();
				const stringB = b.linkedshooters[0].toLowerCase();

				// Compare the strings
				if (stringA < stringB) {
					return -1;
				} else if (stringA > stringB) {
					return 1;
				} else {
					return 0;
				}
			});
			return ar;
		},
	},
	mounted() {
		window.scrollToBottom = this.scrollToBottom;
	},
	watch: {
		value(v) {
			this.show = v;
		},
		show(v) {
			if (this.value !== v) {
				console.log(this.value, v);
				this.$emit('input', v);
			}
			setTimeout(() => {
				this.scrollToBottom(19 * 1000);
			}, 250);
		},
	},
	methods: {
		scrollToBottom(duration) {
			const div = document.querySelector('.v-data-table__wrapper'); // Select the div containing the table

			const start = div.scrollTop;
			const end = div.scrollHeight - div.clientHeight;
			const distance = end - start;
			const startTime = performance.now();

			function scroll(timestamp) {
				const elapsed = timestamp - startTime;
				const progress = elapsed / duration;

				div.scrollTop = start + distance * progress;

				if (progress < 1) {
					requestAnimationFrame(scroll);
				}
			}

			requestAnimationFrame(scroll);
		},
	},
};
</script>

<style scoped>
.forceFill {
	object-fit: fill;
}
#punchTable >>> .v-data-table__wrapper {
	overflow-y: hidden !important;
}
</style>
