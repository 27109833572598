<template>
	<v-container
		fluid
		fill-height
		:style="`${$vuetify.theme.dark ? 'background-color: #121212' : ' '}`"
		v-if="useData"
	>
		<Header @click="showSettings = !showSettings" :header="headerText">
			<template #header-line-2 v-if="data.type !== 'open'">
				<div>
					<span v-if="dataset === 'inState'">
						In State
					</span>
					<span v-else-if="dataset === 'outState'">
						Out of State
					</span>
					<span v-else>
						Open
					</span>
				</div>
			</template>
		</Header>
		<v-row v-for="group in winnersObj" :key="group.Group" :id="group.groupId" @click="showSettings = !showSettings">
			<v-card
				style="width: 100%;"
				:class="{
					'pb-4': true,
					'mb-5': !group.isLast,
					'last-item': group.isLast,
				}"
			>
				<v-card-title class="text-h3 mb-4" style="width:100%">
					<v-row>
						<v-col align="start">
							<span class="font-weight-bold yellow--text text-capitalize">
								{{ getTitle(group) }}
							</span>
						</v-col>
					</v-row>
				</v-card-title>
				<v-card-text :style="fontSizeStyle">
					<p v-if="!group.Winners || !group.Winners.length" class="text-center">
						No entry
					</p>
					<div v-else></div>
					<v-row
						v-for="shooter in group.Winners"
						:key="shooter.ShooterId"
						:class="{
							strike: shooter.strike,
							winner: shooter.isWinner,
						}"
					>
						<v-col align="start" class="text-truncate grow">
							{{ shooter.ShooterName }}
						</v-col>
						<v-col align="center" class="text-truncate">
							{{ shooter.ShootOffStatus }}
						</v-col>
						<v-col align="center" cols="1">
							{{ shooter.State }}
						</v-col>
						<v-col align="center" cols="1">
							{{ shooter.Classification }}
						</v-col>
						<v-col align="start" cols="2">
							{{ shooter.Category }}
						</v-col>
						<v-col align="center" cols="1" class="shrink">
							{{ shooter.Score200 }}
						</v-col>
					</v-row>
				</v-card-text>
			</v-card>
		</v-row>
		<SettingsOverlay
			v-model="showSettings"
			@set-paused="setPaused"
			:paused="paused"
		/>
	</v-container>
</template>

<script>
import Header from './Header.vue';
import SettingsOverlay from './SettingsOverlay.vue';
export default {
	components: { Header, SettingsOverlay },
	props: {
		/**
		 * headerText used
		 */
		headerText: {
			default: null,
		},
		/**
		 * Type of the event S, H, D
		 */
		EvType: {
			default: null,
		},
		data: {
			default: null,
			required: true,
		},
		eventsShown: {
			default: null,
		},
	},
	data() {
		return {
			showSettings: false,
			paused: false,
			dataset: 'open',
			cGroup: 0,
			lastItemShown: false,
			scrollMS: 2 * 1000,
			scrollInterval: null,
			groupsInOrder: [],
			timeouts: {},
			intervals: {},
			isOnDelay: false,
		};
	},
	computed: {
		useData() {
			return this.data[this.dataset];
		},
		winnersObj() {
			let obj = {};

			for (let i = 0; i < this.useData.length; i++) {
				const item = this.useData[i];
				if (!obj[item.Group]) obj[item.Group] = item;
				item.Winners = item.Winners.filter((x) => !x.hide).map((x) => {
					x.isWinner = `${x.ShootOffStatus}`.toLowerCase().includes('winner');
					return x;
				});
				item.isLast = this.useData.length - 1 === i;
				item.groupId =
					'G' + item.Group.replace(/\s+|\.|_/gi, '-').replace(/^-/gi, '');
			}
			return obj;
		},
		/**
		 * Gets the size of the font based on the vuetify breakpoint
		 */
		fontSizeStyle() {
			if (this.$vuetify.breakpoint.xsOnly) {
				return 'font-size: 12pt';
			} else if (this.$vuetify.breakpoint.smAndDown) {
				return 'font-size: 14pt';
			} else if (this.$vuetify.breakpoint.mdAndDown) {
				return 'font-size: 20pt';
			} else if (this.$vuetify.breakpoint.lgAndDown) {
				return 'font-size: 24pt';
			} else if (this.$vuetify.breakpoint.lgAndUp) {
				return 'font-size: 30pt';
			} else {
				// Default value, if none of the conditions match
				return 'font-size: 16pt'; // Replace with your desired default value
			}
		},
		documentScrollDetails() {
			this.cGroup;
			const c = this.groupsInOrder.map(
				(x) =>
					(document.getElementById(x) &&
						document.getElementById(x).clientHeight) ||
					100
			);
			const calcvalues = c.reduce((total, x) => total + x, 0);
			const currentBottom =
				document.documentElement.scrollTop + window.innerHeight;
			return {
				c,
				calcvalues,
				currentBottom,
				b: calcvalues <= currentBottom,
			};
		},
		bottomMostElement() {
			const pos = document.documentElement.scrollTop + window.innerHeight;

			const cumulativeTotal = (arr) =>
				arr.reduce(
					(acc, val) => [
						...acc,
						acc.length > 0 ? acc[acc.length - 1] + val : val,
					],
					[]
				);
			const idx = cumulativeTotal(this.documentScrollDetails.c).findIndex(
				(x) => x > pos
			);
			return {
				idx,
				group: this.groupsInOrder[idx - 1],
			};
		},
		isLastItemVisible() {
			return this.documentScrollDetails.b;
		},
	},
	methods: {
		destroyTimers() {
			Object.keys(this.timeouts).map(this.clearTimeout);
			Object.keys(this.intervals).map(this.clearInterval);
		},
		setTimeout(fn, delay) {
			let timeout = setTimeout(() => {
				fn();
				delete this.timeouts[timeout];
				this.setTimerDebuggingWindowObj();
			}, delay);
			this.timeouts[timeout] = `${delay}ms`;
			return timeout;
		},
		clearTimeout(timeout) {
			clearTimeout(timeout);
			delete this.timeouts[timeout];
			this.setTimerDebuggingWindowObj();
		},
		setInterval(fn, delay) {
			let interval = setInterval(() => {
				fn();
				this.setTimerDebuggingWindowObj();
			}, delay);
			this.intervals[interval] = `${delay}ms`;
			return interval;
		},
		clearInterval(interval) {
			clearInterval(interval);
			delete this.intervals[interval];
			this.setTimerDebuggingWindowObj();
		},
		setTimerDebuggingWindowObj() {
			window.scrollWinnersIntervals = JSON.parse(
				JSON.stringify(this.intervals)
			);
			window.scrollWinnersTimeouts = JSON.parse(JSON.stringify(this.timeouts));
		},
		setPaused(v) {
			this.paused = v;
		},
		getTitle(group) {
			if (group.Type === 'Place') {
				return this.getPlace(group.Place);
			} else {
				return `${group.Subtype} ${this.getPlace(group.Place)}`;
			}
			// return group.Group;
		},
		getPlace(place) {
			if (place == 1) {
				return 'Champion';
			} else if (place == 2) {
				return 'Runner Up';
			} else if (place == 3) {
				return '3RD';
			} else {
				return `${place}TH`;
			}
		},
		scroll() {
			if (this.isOnDelay) {
				this.isOnDelay = false;
				return;
			}
			if (this.cGroup === -1) {
				this.cGroup++;
				this.$vuetify.goTo(`#${this.groupsInOrder[this.cGroup]}`);

				return;
			}
			if (this.lastItemShown || this.bottomMostElement.idx < 0) {
				return this.nextScreen();
			}
			if (this.bottomMostElement.idx - 1 > this.groupsInOrder.length - 1) {
				this.cGroup = this.groupsInOrder.length - 1;
			} else {
				this.cGroup = this.bottomMostElement.idx - 1;
			}
			const st = document.documentElement.scrollTop;
			this.$vuetify.goTo(`#${this.groupsInOrder[this.cGroup]}`);
			if (st === document.documentElement.scrollTop) {
				this.$vuetify.goTo(
					`#${this.groupsInOrder[this.groupsInOrder.length - 1]}`
				);
			}
		},
		nextScreen() {
			if (
				this.data.type === 'open' ||
				this.dataset === 'outState' ||
				this.dataset === 'open'
			) {
				this.$emit('LastGroupShown');
			} else if (this.data.type === 'open') {
				this.$emit('LastGroupShown');
			} else {
				this.dataset = this.data.type.split('-')[1] + 'State';
				this.cGroup = -1;
				this.scroll();
				return;
			}
			this.cGroup = -1;
			//If now showing wait to reset display until popup is active
			this.isOnDelay = false;
			if (this.$store.state.NowShowing || this.$store.state.yardagePunches?.length) {
				this.isOnDelay = true;
			}
		},
		startScrolling() {
			this.scrollInterval = this.setInterval(this.scroll, this.scrollMS);
			window.scrollIntervalWinners = this.scrollInterval;
			this.setTimeout(() => {
				this.cGroup = -1;
				this.scroll();
			}, 500);
		},
		setupType() {
			if (this.data.type !== 'open') {
				this.dataset = this.data.type.split('-')[0] + 'State';
			}
		},
	},
	watch: {
		cGroup(v) {
			if (v > this.groupsInOrder.length - 1) {
				this.cGroup = 0;
			}
		},
		isLastItemVisible(v) {
			this.lastItemShown = v;
			if (this.lastItemShown) this.cGroup = this.groupsInOrder.length - 1;
		},
		data: {
			deep: true,
			handler() {
				let g = this.useData.map((x) => x.groupId);
				this.groupsInOrder = g.filter((x, y) => g.indexOf(x) === y);
			},
		},
	},
	mounted() {
		this.setupType();
		if (window.scrollIntervalWinners)
			this.clearInterval(window.scrollIntervalWinners);
		window.scroll = this.scroll;
		if (this.$store.state.ScrollTime) {
			this.scrollMS = this.$store.state.ScrollTime;
		}
		if (
			(this.$store.state.NowShowing ||
				this.$store.state.yardagePunches?.length) &&
			this.eventsShown
		) {
			let offset = 0;
			if (window.punchesOverlay) {
				offset += 25 * 1000;
			}
			this.setTimeout(() => {
				this.startScrolling();
			}, 5000 + offset);
		} else {
			this.startScrolling();
		}
	},
	beforeDestroy() {
		clearInterval(this.scrollInterval);
		this.destroyTimers();
	},
	beforeUnmount() {
		window.removeEventListener('keydown', this.keyboardShortcuts);
		this.destroyTimers();
	},
	beforeRouteLeave() {
		this.destroyTimers();
	},
};
</script>

<style>
.strike {
	position: relative;
}
.strike:before {
	content: ' ';
	position: absolute;
	top: 50%;
	left: 1%;
	border-bottom: 2px solid;
	width: 98%;
}
.last-item {
	margin-bottom: 75px !important;
}
.winner {
	font-weight: bolder;
}
</style>
