<template>
	<v-col
		v-if="$vuetify.breakpoint.lgAndUp"
		cols="auto"
		align="center"
		:class="{
			'mr-1': true,
			score: true,
			maxScore: value == maxScore,
		}"
	>
		{{ value >= 10 ? value : ('0' + value) | doubleZero }}
	</v-col>
</template>

<script>
export default {
	filters: {
		doubleZero(v) {
			try {
				if (v == '00') {
					return '--';
				} else {
					return v;
				}
			} catch (err) {
				return v;
			}
		},
	},
	props: {
		value: {
			type: Number,
			required: true,
		},
		maxScore: {
			type: Number,
			required: true,
		},
	},
};
</script>

<style scoped>
.maxScore {
	font-weight: bold;
	color: #f00;
}
.score {
	font-family: "Roboto Mono", monospace;
}
</style>
