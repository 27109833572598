var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.useData)?_c('v-container',{style:(("" + (_vm.$vuetify.theme.dark ? 'background-color: #121212' : ' '))),attrs:{"fluid":"","fill-height":""}},[_c('Header',{attrs:{"header":_vm.headerText},on:{"click":function($event){_vm.showSettings = !_vm.showSettings}},scopedSlots:_vm._u([(_vm.data.type !== 'open')?{key:"header-line-2",fn:function(){return [_c('div',[(_vm.dataset === 'inState')?_c('span',[_vm._v(" In State ")]):(_vm.dataset === 'outState')?_c('span',[_vm._v(" Out of State ")]):_c('span',[_vm._v(" Open ")])])]},proxy:true}:null],null,true)}),_vm._l((_vm.winnersObj),function(group){return _c('v-row',{key:group.Group,attrs:{"id":group.groupId},on:{"click":function($event){_vm.showSettings = !_vm.showSettings}}},[_c('v-card',{class:{
				'pb-4': true,
				'mb-5': !group.isLast,
				'last-item': group.isLast,
			},staticStyle:{"width":"100%"}},[_c('v-card-title',{staticClass:"text-h3 mb-4",staticStyle:{"width":"100%"}},[_c('v-row',[_c('v-col',{attrs:{"align":"start"}},[_c('span',{staticClass:"font-weight-bold yellow--text text-capitalize"},[_vm._v(" "+_vm._s(_vm.getTitle(group))+" ")])])],1)],1),_c('v-card-text',{style:(_vm.fontSizeStyle)},[(!group.Winners || !group.Winners.length)?_c('p',{staticClass:"text-center"},[_vm._v(" No entry ")]):_c('div'),_vm._l((group.Winners),function(shooter){return _c('v-row',{key:shooter.ShooterId,class:{
						strike: shooter.strike,
						winner: shooter.isWinner,
					}},[_c('v-col',{staticClass:"text-truncate grow",attrs:{"align":"start"}},[_vm._v(" "+_vm._s(shooter.ShooterName)+" ")]),_c('v-col',{staticClass:"text-truncate",attrs:{"align":"center"}},[_vm._v(" "+_vm._s(shooter.ShootOffStatus)+" ")]),_c('v-col',{attrs:{"align":"center","cols":"1"}},[_vm._v(" "+_vm._s(shooter.State)+" ")]),_c('v-col',{attrs:{"align":"center","cols":"1"}},[_vm._v(" "+_vm._s(shooter.Classification)+" ")]),_c('v-col',{attrs:{"align":"start","cols":"2"}},[_vm._v(" "+_vm._s(shooter.Category)+" ")]),_c('v-col',{staticClass:"shrink",attrs:{"align":"center","cols":"1"}},[_vm._v(" "+_vm._s(shooter.Score200)+" ")])],1)})],2)],1)],1)}),_c('SettingsOverlay',{attrs:{"paused":_vm.paused},on:{"set-paused":_vm.setPaused},model:{value:(_vm.showSettings),callback:function ($$v) {_vm.showSettings=$$v},expression:"showSettings"}})],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }