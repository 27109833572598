<template>
	<v-bottom-sheet
		v-model="showSettings"
		hide-overlay
		:dark="!$vuetify.theme.dark"
		:light="$vuetify.theme.dark"
		style="z-index:9999999"
	>
		<v-sheet class="text-center" height="75px">
			<v-row class="mx-5">
				<v-col align="start">
					<v-btn
						:color="menuItem == 0 ? 'primary' : ''"
						@mouseover="menuItem = 0"
						@click="$router.push('/set/shoot')"
						fab
					>
						<v-icon> mdi-chevron-left </v-icon>
					</v-btn>
				</v-col>
				<v-col>
					<v-btn
						:color="menuItem == 1 ? 'primary' : ''"
						@click="togglePause"
						@mouseover="menuItem = 1"
						fab
					>
						<v-icon v-if="paused"> mdi-play </v-icon>
						<v-icon v-if="!paused"> mdi-pause </v-icon>
					</v-btn>
				</v-col>
				<v-col>
					<v-btn
						:color="menuItem == 2 ? 'primary' : ''"
						@click="showSettings = false"
						@mouseover="menuItem = 2"
						fab
					>
						<v-icon> mdi-close </v-icon>
					</v-btn>
				</v-col>
				<v-col>
					<v-btn
						:color="menuItem == 3 ? 'primary' : ''"
						@click="$router.push('/settings')"
						@mouseover="menuItem = 3"
						fab
					>
						<v-icon> mdi-cog </v-icon>
					</v-btn>
				</v-col>
				<v-col align="end">
					<v-btn
						:color="menuItem == 4 ? 'primary' : ''"
						@click="toggleDark()"
						@mouseover="menuItem = 4"
						fab
					>
						<v-icon large v-if="$vuetify.theme.dark">
							mdi-weather-sunny
						</v-icon>
						<v-icon large v-else> mdi-moon-waning-crescent </v-icon>
					</v-btn>
				</v-col>
			</v-row>
		</v-sheet>
		<v-overlay :value="paused">
			<center>
				<v-icon size="255" @click="togglePause()"> mdi-pause-circle </v-icon>
			</center>
		</v-overlay>
	</v-bottom-sheet>
</template>

<script>
export default {
	props: {
		value: {
			type: Boolean,
			required: true,
		},
		paused: {
			type: Boolean,
			required: true,
		},
	},
	data() {
		return {
			menuItem: 2,
			showSettings: this.value,
		};
	},
	watch: {
		value(newValue) {
			if (newValue != this.showSettings);
			{
				this.showSettings = newValue;
			}
		},
		showSettings(newShowSettings) {
			this.$emit('input', newShowSettings);
		},
		paused(v) {
			this.showSettings = this.showSettings || v;
		},
	},
	mounted() {
		window.removeEventListener('keydown', this.keyboardShortcuts);
		window.addEventListener('keydown', this.keyboardShortcuts);
	},
	beforeDestroy() {
		//Perform clean up and clear the keyboard events and the interval
		window.removeEventListener('keydown', this.keyboardShortcuts);
	},
	methods: {
		/**
		 * Toggles the dark theme
		 */
		toggleDark() {
			this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
			localStorage.darkmode = this.$vuetify.theme.dark;
		},
		/**
		 * Toggles the pause state
		 */
		togglePause() {
			this.$emit('set-paused', !this.paused);
		},
		keyboardShortcuts(key) {
			//Stop scrolling until the timer (see bottom of this function) is done
			this.preventScroll = true;

			//Stop the prevent interval because it is about to be set again
			clearInterval(this.preventItrvl);
			this.nextBlocked = false;

			//Handle keypress with appropriate action
			switch (key.keyCode) {
				// ESCAPE
				case 27:
					this.$router.push('/set/shoot');
					break;
				// LEFT
				case 37:
					this.preventScroll = false;
					this.menuItem--;
					if (this.menuItem < 0) this.menuItem = 4;
					break;

				// RIGHT
				case 39:
					this.preventScroll = false;
					this.menuItem++;
					if (this.menuItem > 4) this.menuItem = 0;
					break;

				//ENTER
				case 13:
					this.preventScroll = false;
					if (!this.showSettings) {
						this.menuItem = 2;
						this.showSettings = true;
						break;
					}
					switch (this.menuItem) {
						case 0:
							this.$router.push('/set/shoot');
							break;
						case 1:
							this.togglePause();
							break;
						case 2:
							this.showSettings = false;
							break;
						case 3:
							this.$router.push('/settings');
							break;
						case 4:
							this.toggleDark();
							break;
					}
					break;

				//PAUSE / SPACE
				case 32:
				case 179:
					this.togglePause();
					break;
			}

			//Start an interval preventing scrolling from occuring until after no input has been receieved for one minute
			this.preventItrvl = setInterval(() => {
				this.preventScroll = false;
				clearInterval(this.preventItrvl);
			}, 1 * 60 * 1000);
		},
	},
};
</script>

<style></style>
