<template>
	<v-app-bar
		class="prevent-select"
		@click="c"
		prominent
		app
		:short="true"
		elevation="1"
		id="hdr"
		:dark="true"
	>
		<v-row
			:style="
				$vuetify.breakpoint.xsOnly
					? `font-size: 13pt`
					: $vuetify.breakpoint.smAndDown
					? `font-size: 16pt`
					: ''
			"
		>
			<v-col
				align="center"
				justify="center"
				style="text-align: center; height: 100%;"
			>
				<v-row
					align="center"
					justify="center"
					:class="{
						'mt-1': !!$slots['header-line-2'],
					}"
					:style="$slots['header-line-2'] || 'height: 112px;'"
				>
					<h2 style="font-weight: normal">
						{{ header }}
					</h2>
				</v-row>
				<v-row align="center" justify="center" v-if="$slots['header-line-2']">
					<h2 class="text-truncate">
						<slot name="header-line-2"> </slot>
					</h2>
				</v-row>
			</v-col>
		</v-row>
		<v-btn text absolute bottom left v-if="!$vuetify.breakpoint.smAndDown">
			<h2>{{ Time }}</h2>
		</v-btn>
		<v-btn
			text
			absolute
			top
			left
			class="mt-4"
			v-if="!$vuetify.breakpoint.smAndDown && onDeckBoard.show"
		>
			<h1 :class="shootingSquadColor" style="transition: all 0.5s ease-in;">
				On Deck: {{ onDeckBoard.startingSquad }}-{{ onDeckBoard.lastSquad }}
			</h1>
		</v-btn>
		<slot name="scores-entered"> </slot>
	</v-app-bar>
</template>

<script>
import { mapState } from 'vuex';

export default {
	props: {
		header: {
			type: String,
			required: false,
		},
	},
	data() {
		return {
			Time: null,
			TimeInterval: null,
		};
	},
	beforeDestroy() {
		//Perform clean up and clear the keyboard events and the interval
		clearInterval(this.TimeInterval);
	},
	mounted() {
		const getTime = () => {
			let d = new Date();
			this.Time = d.toLocaleString('en-US', {
				hour: 'numeric',
				minute: 'numeric',
				//second: 'numeric',
				hour12: true,
			});
		};

		getTime();
		clearInterval(this.TimeInterval);
		this.TimeInterval = setInterval(getTime, 10 * 1000);
		this.startingSquad = this.onDeckBoard.startingSquad;
	},
	computed: {
		...mapState('shoot', ['onDeckBoard']),
	},
	watch: {
		onDeckBoard: {
			handler(v) {
				if (v.startingSquad != this.startingSquad) {
					this.shootingSquadColor = 'yellow--text';
					setTimeout(() => {
						this.shootingSquadColor = 'white--text';
						this.startingSquad = v.startingSquad;
					}, 2500);
				}
			},
			deep: true,
		},
	},
	methods: {
		c() {
			this.$emit('click');
		},
	},
};
</script>

<style>
.prevent-select {
	-webkit-user-select: none; /* Safari */
	-ms-user-select: none; /* IE 10 and IE 11 */
	user-select: none; /* Standard syntax */
}
</style>
