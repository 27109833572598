<template>
	<screen-dialog v-model="ShowNationalAnthem" noLogo>
		<center>
			<video
				v-if="playable"
				id="videoPlayerForNationalAnthem"
				autoplay
				style="width: 80%; height: auto"
				ref="vid"
			>
				<source :src="video" type="video/mp4" />
			</video>
		</center>
	</screen-dialog>
</template>

<script>
import ScreenDialog from './ScreenDialog.vue';
export default {
	components: { ScreenDialog },
	data() {
		return {
			video: '',
			ShowNationalAnthem: false,
			playable: true,
		};
	},
	mounted() {
		this.$ServerBus.on('scoreboard-play-anthem', this.showAnthem);
	},
	beforeDestroy() {
		this.$ServerBus.on('scoreboard-play-anthem', this.showAnthem);
	},
	methods: {
		showAnthem(video) {
			const burl = 'https://straight-score-videos.s3.amazonaws.com';
			let vurl = '';
			let Quality = 'good';
			let res = '720x480';
			let downLink = NetworkInformation.downlink || 10;

			if (downLink > 100) {
				Quality = 'best';
			}

			if (downLink > 200) {
				res = '1920x1080';
			} else if (downLink > 400) {
				res = '4096x2160';
			}

			switch (video) {
				case 'USA':
					vurl = `${burl}/USA+ONLY/${res}+25+fps+${Quality}+quality.mp4`;
					break;
				case 'CANADA':
					vurl = `${burl}/CANADA+ONLY/${res}+25fps+${Quality}+quality.mp4`;
					break;
				case 'BOTH':
					vurl = `${burl}/USA+%26+CANADA/${res}+25+fps+${Quality}+quality.mp4`;
					break;
			}

			this.video = vurl;
			this.ShowNationalAnthem = true;

			this.$nextTick(() => {
				const videoEle = document.getElementById(
					'videoPlayerForNationalAnthem'
				);
				videoEle.onplay = () => {
					if (videoEle.requestFullscreen) {
						videoEle.requestFullscreen();
					} else if (videoEle.mozRequestFullScreen) {
						videoEle.mozRequestFullScreen();
					} else if (videoEle.webkitRequestFullscreen) {
						videoEle.webkitRequestFullscreen();
					} else if (videoEle.msRequestFullscreen) {
						videoEle.msRequestFullscreen();
					}
				};

				videoEle.onended = () => {
					this.ShowNationalAnthem = false;
					this.video = '';
					this.playable = false;
					setTimeout(() => {
						this.playable = true;
					}, 1000);
				};
			});
		},
	},
};
</script>

<style>
</style>