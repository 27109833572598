<template>
	<screen-dialog v-model="showODB" style="height:100vh">
		<v-container v-if="onDeckBoard.popupVariant === 'range'" fluid fill-height>
			<v-card elevation="0" width="100%">
				<center>
					<h1 class="text-h1 mb-5" style="font-weight:bold">
						On Deck
					</h1>
					<h2 class="text-h1">
						Squad
						<span style="font-weight:bold">
							{{ starting }}
						</span>
						through
						<span style="font-weight:bold">
							{{ ending }}
						</span>
					</h2>
				</center>
			</v-card>
		</v-container>

		<v-container
			v-if="onDeckBoard.popupVariant === 'grid'"
			class="text-center"
			fluid
			fill-height
		>
			<v-card width="100%" elevation="0">
				<v-simple-table>
					<tr>
						<td></td>
						<td
							v-for="v in onDeckBoard.bankLabels"
							:key="v"
							class="text-center"
						>
							<h1 class="text-h3" style="opacity:0.5">
								<span
									v-if="
										!`${v}`.toLowerCase().includes('bank') &&
											onDeckBoard.bankLabels.length < 6
									"
								>
									Bank
								</span>
								{{ v }}
							</h1>
						</td>
					</tr>
					<tr v-for="(row, count) in items" :key="row[0]" align="center">
						<td style="opacity:0.5">
							<h1 v-if="count === 0" class="text-h3 mb-10">
								Shooting
							</h1>
							<h1 v-else-if="count === 1" class="text-h3 mb-10">
								On Deck
							</h1>
							<h1 v-else-if="count === 2" class="text-h3 mb-10">
								Upcoming
							</h1>
						</td>
						<td v-for="val in row" :key="val" class="text-center">
							<h1 class="text-h3">{{ val }}</h1>
						</td>
					</tr>
				</v-simple-table>
			</v-card>
		</v-container>
	</screen-dialog>
</template>

<script>
import { mapState } from 'vuex';
import ScreenDialog from '../ScreenDialog.vue';
export default {
	components: { ScreenDialog },
	props: {
		force: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			showODB: false,
			interval: null,
		};
	},
	watch: {
		force(v) {
			if (v) {
				this.showODB = true;
			}
		},
	},
	computed: {
		...mapState('shoot', ['onDeckBoard']),
		items() {
			let r = [];
			const numberBanks = this.onDeckBoard.bankLabels.length;
			for (let index = 0; index < 3; index++) {
				const startingSquad =
					this.onDeckBoard.startingSquad + index * numberBanks;

				r.push(
					Array.from({ length: numberBanks }, (v, k) => k + startingSquad)
				);
			}
			return r;
		},
		starting() {
			return this.onDeckBoard.startingSquad;
		},
		ending() {
			return this.onDeckBoard.lastSquad;
		},
	},
	mounted() {
		if (this.force) {
			this.showODB = true;
		}
		//Show ODB for 30 seconds every three minutes
		this.interval = setInterval(() => {
			if (this.showODB) return;
			this.showODB = true;
			setTimeout(() => {
				if (this.force) {
					return;
				}
				this.showODB = false;
			}, 30 * 1000);
		}, 3 * 60 * 1000);
	},
	beforeDestroy() {
		clearInterval(this.interval);
	},
	methods: {},
};
</script>

<style scoped>
.forceFill {
	object-fit: fill;
}
</style>
