<template>
	<v-container
		fluid
		fill-height
		class="pa-0"
		:style="`${$vuetify.theme.dark ? 'background-color: #121212' : ' '}`"
	>
		<Header
			@click="showSettings = !showSettings"
			:header="headerText"
			:beforeTitle="beforeTitle"
			:allTitles="allTitles"
		>
			<template #header-line-2>
				{{ beforeTitle.toUpperCase() }}
				{{ allTitles[curClass + 1].toUpperCase() }}
			</template>

			<template #scores-entered>
				<v-btn
					text
					absolute
					bottom
					right
					v-if="allScores && !$vuetify.breakpoint.smAndDown"
				>
					<h2>Entered: {{ enteredScores }} / {{ inScores.length }}</h2>
				</v-btn>
			</template>
		</Header>

		<v-row
			align="center"
			style="height: calc( 100vh - 112px);"
			v-show="scores.length === 0"
		>
			<v-col align="center">
				<h1 style="font-size:40pt" class="mb-10">
					No scores in
					{{ allTitles[curClass + 1] }}
				</h1>
				<v-img
					:eager="true"
					contain
					src="/images/shootpro_logo_min.png"
					max-width="500"
					min-width="24"
					style="width: 100%!important"
					class="mt-10"
				/>
			</v-col>
		</v-row>

		<v-list
			@scroll.prevent=""
			flat
			class="mx-4"
			:style="
				`width: 100%;${
					$vuetify.theme.dark ? 'background-color: #121212' : ' '
				} `
			"
		>
			<v-list-item-group :class="runAnimation ? 'shadow-drop-bottom' : ''">
				<v-list-item
					v-for="(sc, idx) in scores"
					:key="idx"
					:id="`i${idx}`"
					@click="showSettings = !showSettings"
					:class="`btmLine text-truncate ` + sc.Disqualified || 'strikeout'"
					:style="fontSizeStyle"
				>
					<v-list-item-icon :key="ScoreKey">
						{{ sc.State }}
					</v-list-item-icon>

					<v-list-item-content>
						<v-row align="center" :key="ScoreKey">
							<!-- <v-col lg="4" md="6" sm="7" align="center"> -->
							<v-col
								align="center"
								class="text-truncate grow"
								style="display: block"
							>
								{{ sc.ShooterName }}
							</v-col>
							<!-- <v-col>SQD</v-col> -->
							<v-col align="center" cols="1" class="grow">
								{{ sc.Classification }}
							</v-col>
							<v-col align="center" cols="1" class="grow">
								{{ sc.Category | Open }}
							</v-col>
							<v-col
								cols="2"
								:class="sc.TotalScore == targetsInEvent ? 'maxScore' : ''"
								align="center"
								style="font-weight: bolder"
								class="grow"
							>
								{{ sc.TotalScore }}
							</v-col>
							<!-- v-for="i in (1, 8)" -->
							<v-col align="end" class="shrink">
								<v-row
									align="center"
									justify="end"
									style="flex-wrap: nowrap;"
									dense
								>
									<SubEventScore
										v-for="i in (1, targetsInEvent / maxSubEvt)"
										:key="i"
										v-show="!hoa"
										:value="sc[`Score${i}`]"
										:maxScore="maxSubEvt"
									/>
								</v-row>
							</v-col>
						</v-row>
					</v-list-item-content>
				</v-list-item>
			</v-list-item-group>
		</v-list>

		<SettingsOverlay
			v-model="showSettings"
			@set-paused="setPaused"
			:paused="paused"
		/>
	</v-container>
</template>

<script>
import lodash from 'lodash';
import SubEventScore from './SubEventScore.vue';
import Header from './Header.vue';
import SettingsOverlay from './SettingsOverlay.vue';
export default {
	filters: {
		Open(ScoreCategory) {
			try {
				return ScoreCategory.replace('None', 'OPEN');
			} catch {
				return ScoreCategory;
			}
		},
	},
	components: { SubEventScore, Header, SettingsOverlay },
	props: {
		/**
		 * Scores to be used
		 */
		inScores: {
			default: null,
		},

		/**
		 * headerText used
		 */
		headerText: {
			default: null,
		},

		/**
		 * Type of the event S, H, D
		 */
		EvType: {
			default: null,
		},

		/**
		 * LIST OF CLASSES TO BE DISPLAYED
		 */
		displayClasses: {
			default: null,
		},

		/**
		 * LIST OF CATEGORIES TO BE DISPLAYED
		 */
		displayCategories: {
			default: null,
		},

		/**
		 * NUMBER OF TARGETS IN THE EVENT
		 */
		targetsInEvent: {
			default: 100,
		},

		/**
		 * IS SCROLLING HOA/HAA
		 */
		hoa: {
			default: null,
			type: Boolean,
		},
	},
	data() {
		return {
			limitToTheTopXScores: null,
			scrollMS: 15 * 1000,
			scrolling: false,
			nextBlocked: false,
			allScores: null,
			scores: [],
			curRec: 0,
			curClass: -1,
			paused: false,
			preventScroll: false,
			itrvl: null,
			preventItrvl: null,
			beforeTitle: '',
			runAnimation: false,
			showSettings: false,
			ScoreKey: 0,
			skipBlank: null,
			startingSquad: 0,
			shootingSquadColor: 'white--text',
			timeouts: {},
			intervals: {},
		};
	},
	beforeDestroy() {
		//Perform clean up and clear the keyboard events and the interval
		window.removeEventListener('keydown', this.keyboardShortcuts);
		this.clearInterval(this.itrvl);
		this.destroyTimers();
	},
	beforeUnmount() {
		window.removeEventListener('keydown', this.keyboardShortcuts);
		this.destroyTimers();
	},
	beforeRouteLeave() {
		this.destroyTimers();
	},
	computed: {
		/**
		 * Returns the number of entered scores
		 */
		enteredScores() {
			//
			if (this.targetsInEvent == 200) {
				//If second scores are entered
				if (this.allScores.filter((x) => x.Score100_2).length)
					return this.allScores.filter((x) => x.Score100_2).length;
			}
			return this.allScores.length;
		},
		/**
		 * Is the event type doubles?
		 */
		isDoubles() {
			return this.EvType == 'Doubles';
		},
		/**
		 * Calculates based on event type if the top score for a subevt is 25 or 50
		 */
		maxSubEvt() {
			return this.EvType == 'Doubles' ? 50 : 25;
		},
		/**
		 * Gets the total height of the window
		 */
		windowHeight() {
			return window.innerHeight;
		},
		/**
		 * Returns the title bar height
		 */
		titleHeight() {
			return document.getElementById('hdr').clientHeight;
		},
		/**
		 * Gets all titles in array format
		 */
		allTitles() {
			return [
				'All Scores',
				...(this.displayClasses ? this.displayClasses : []),
				...(this.displayCategories ? this.displayCategories : []),
			];
		},
		/**
		 * Get the height of a single row
		 */
		rowHeight() {
			try {
				return document.getElementById('i0').clientHeight;
			} catch (err) {
				err;
				return 50;
			}
		},
		/**
		 * Gets the total number of screens
		 */
		totalSubScreens() {
			return (
				(this.displayClasses ? this.displayClasses.length : 0) +
				(this.displayCategories ? this.displayCategories.length : 0)
			);
		},
		/**
		 * GET NUMBER OF RECORDS USE ROUND SO IF THE
		 * REC AT THE BTM IS CUT OFF BY HALF IT WILL
		 * MOVE TO TOP OTHERWISE IT WILL BE SKIPPED
		 */
		numRec() {
			try {
				return Math.round(
					(this.windowHeight - this.titleHeight) / this.rowHeight
				);
			} catch (err) {
				err;
				return 1;
			}
		},
		/**
		 * Gets the size of the font based on the vuetify breakpoint
		 */
		fontSizeStyle() {
			if (this.$vuetify.breakpoint.xsOnly) {
				return 'font-size: 14pt';
			} else if (this.$vuetify.breakpoint.smAndDown) {
				return 'font-size: 20pt';
			} else if (this.$vuetify.breakpoint.mdAndDown) {
				return 'font-size: 24pt';
			} else if (this.$vuetify.breakpoint.lgAndDown) {
				return 'font-size: 30pt';
			} else if (this.$vuetify.breakpoint.lgAndUp) {
				return 'font-size: 34pt';
			} else {
				// Default value, if none of the conditions match
				return 'font-size: 16pt'; // Replace with your desired default value
			}
		},
	},
	methods: {
		destroyTimers() {
			Object.keys(this.timeouts).map(this.clearTimeout);
			Object.keys(this.intervals).map(this.clearInterval);
		},
		setTimeout(fn, delay) {
			let timeout = setTimeout(() => {
				fn();
				delete this.timeouts[timeout];
				this.setTimerDebuggingWindowObj();
			}, delay);
			this.timeouts[timeout] = `${delay}ms`;
			return timeout;
		},
		clearTimeout(timeout) {
			clearTimeout(timeout);
			delete this.timeouts[timeout];
			this.setTimerDebuggingWindowObj();
		},
		setInterval(fn, delay) {
			let interval = setInterval(() => {
				fn();
				this.setTimerDebuggingWindowObj();
			}, delay);
			this.intervals[interval] = `${delay}ms`;
			return interval;
		},
		clearInterval(interval) {
			clearInterval(interval);
			delete this.intervals[interval];
			this.setTimerDebuggingWindowObj();
		},
		setTimerDebuggingWindowObj() {
			window.scrollScoresIntervals = JSON.parse(JSON.stringify(this.intervals));
			window.scrollScoresTimeouts = JSON.parse(JSON.stringify(this.timeouts));
		},
		keyboardShortcuts(key) {
			//Stop scrolling until the timer (see bottom of this function) is done
			this.preventScroll = true;

			//Stop the prevent interval because it is about to be set again
			this.clearInterval(this.preventItrvl);
			this.nextBlocked = false;

			//Handle keypress with appropriate action
			switch (key.keyCode) {
				//UP
				case 38:
					this.scrollUp();
					break;

				//DOWN
				case 40:
					this.scroll();
					break;
				//ENTER
				case 13:
					this.preventScroll = false;
					if (!this.showSettings) {
						this.menuItem = 2;
						this.showSettings = true;
						break;
					}
					break;

				//PLUS & FAST FORWARD
				case 228:
				case 107:
				case 187:
					this.nextScreen();
					break;

				//BACK & REWIND
				case 227:
				case 109:
				case 189:
					this.priorScreen();
					break;
			}

			//Start an interval preventing scrolling from occuring until after no input has been receieved for one minute
			this.preventItrvl = this.setInterval(() => {
				this.preventScroll = false;
				this.clearInterval(this.preventItrvl);
			}, 1 * 60 * 1000);
		},
		tick() {
			//IF PAUSED DO NOT CONTINUE
			if (this.paused || this.preventScroll) return;

			//IF SCROLLING MOVE TO BOTTOM OTHERWISE MOVE TO THE NEXT SCREEN
			if (this.scrolling) {
				this.scroll();
			} else {
				this.nextScreen();
			}
		},
		priorScreen() {
			//Verify we are not on the 0th index if we are move back to the previous screen
			if (this.curClass <= -1) this.curClass = this.totalSubScreens;

			//Move back two screens
			this.curClass -= 2;

			//Trigger draw
			this.nextScreen();
		},
		nextScreen(code = '') {
			if (this.skipBlank) this.clearInterval(this.skipBlank);

			if (this.nextBlocked) return;
			// // console.info('nextScreen');
			this.setTimeout(() => {
				//Wait for data to be loaded then move to the top record
				try {
					//Try needed for times when now showing is enabled
					this.$vuetify.goTo('#i0');
				} catch (error) {
					if (!error.toString().includes('Target element "#i0" not found.'))
						console.info(error);
				}
			}, 150);
			this.nextBlocked = true;
			// console.info('Next Screen Disabled!');

			//We have reached the last index. If we attempt to increment then we will be out of bounds.
			if (
				this.totalSubScreens - 1 == this.curClass ||
				(this.totalSubScreens == 1 && this.curClass == -1)
			) {
				//Alert parent the last group has been shown
				this.$emit('LastGroupShown');

				//On the last screen we need to goto -2 so when we increment later we start on -1 which is negative scores
				this.curClass = -2;

				//If we are only showing all scores then trigger load
				if (!this.displayCategories && !this.displayClasses)
					this.$emit('SecondToLast');
			}

			//If we are on the second to last score group to be shown then trigger loading scores to Vuex
			if (this.totalSubScreens - 2 == this.curClass) this.$emit('SecondToLast');

			//Increment the class
			this.curClass++;

			/**
			 * When curclass == -1 the settings is allScores
			 * When curclass is less than the display classes length show the classes
			 * Otherwise show the categories
			 */
			if (this.curClass == -1) {
				this.beforeTitle = '';
				this.scores = JSON.parse(JSON.stringify(this.allScores));
			} else if (this.curClass < this.displayClasses.length) {
				if (this.EvType !== 'Handicap') {
					this.scores = lodash.filter(
						JSON.parse(JSON.stringify(this.allScores)),
						(x) => x.Classification == this.displayClasses[this.curClass]
					);

					this.beforeTitle = 'Class - ';
				} else {
					const groupDetails = this.displayClasses[this.curClass]
						.replace(/yds/gi, '')
						.replace(/ /g, '')
						.split('to');
					const min = parseFloat(groupDetails[0]);
					const max = parseFloat(groupDetails[1]);
					this.scores = lodash.filter(
						JSON.parse(JSON.stringify(this.allScores)),
						(x) => x.Classification >= min && x.Classification <= max
					);
				}
			} else {
				//Offset current class by the number of classes to get the header id
				this.scores = lodash.filter(
					JSON.parse(JSON.stringify(this.allScores)),
					(x) =>
						x.Category ==
						this.displayCategories[this.curClass - this.displayClasses.length]
				);
				this.beforeTitle = 'Category - ';
			}

			this.setLimit();

			//Reset the scroll record
			this.curRec = 0;

			//If there are no scores skip the screen
			const scoreLen = this.scores.length;
			let percentScreenFilled =
				Math.floor((scoreLen / this.numRec) * 100) / 100;
			if (percentScreenFilled > 1) {
				console.warn('percentScreenFilled > 1');
				percentScreenFilled = 1;
			}
			if (scoreLen < this.numRec - 1) {
				let delayInc = 0;
				if (this.curClass === -1 && this.$store.state.yardagePunches?.length) {
					delayInc += 5 * 1000; //delay 5s for yardage punches
				}

				var delay = this.scrollMS * percentScreenFilled;

				if (delay < 2000 && scoreLen == 0) delay = 2000;
				else if (delay < 4000 && scoreLen >= 1) delay = 4000;
				console.debug('scroll delay modified', {
					scoreLen,
					numRec: this.numRec,
					percentScreenFilled,
					desiredDelay: this.scrollMS * percentScreenFilled,
					actualDelay: delay,
				});
				//Prevent Recursion Errors
				if (code != 'no-scores') this.nextScreen('no-scores');
				this.nextBlocked = false;
				this.clearInterval(this.itrvl);
				this.skipBlank = this.setTimeout(() => {
					this.nextScreen();
					this.startInterval();
				}, delay + delayInc);
				return;
			}

			this.setTimeout(() => {
				this.nextBlocked = false;
				// console.info('Next Screen Enabled Again!');
			}, 5 * 1000);
		},

		/**
		 * Sets the limit of scores from the limit scores setting
		 */
		setLimit() {
			if (this.scores) {
				//Remove zero scores
				this.scores = this.scores.filter((x) => x.TotalScore > 0);
				this.ScoreKey++;
			}
			if (this.limitToTheTopXScores && this.scores) {
				const scr = Object.keys(this.scores).map((k) => this.scores[k]);
				//Get the unique scores
				let uniqueScores = [...new Set(scr.map((x) => x.Score200))];

				//Sort from low to high
				uniqueScores.sort((a, b) => a - b);

				//Sort from high to low
				uniqueScores.reverse();

				//Top score index
				let cidx = this.limitToTheTopXScores - 1;
				if (cidx >= uniqueScores.length - 1) cidx = uniqueScores.length - 1;

				//Get the last score
				let minScores = uniqueScores[cidx];

				//Filter out scores
				this.scores = lodash.filter(
					this.scores,
					(x) => x.Score200 >= minScores
				);
			}
		},

		/**
		 * Reverses the scroll order, by moving up the number of records displayed minus 1
		 */
		scrollUp() {
			//Calculate twice the number of scores on the screen
			let back = this.numRec * 2;

			//Get the record number that is 2x off the screen
			this.curRec = this.curRec - back;

			//If it is past 0 then make it where the first record is shown
			if (this.curRec < 0) {
				this.curRec = this.numRec * -1;
				this.curRec++;
			}

			//Trigger scroll which will move forward and handle the drawing routine.
			this.scroll();
		},

		/**
		 * Scrolls records if we have reached the end of the screen
		 * then it scrolls to the next set of results.
		 */
		scroll() {
			if (this.curRec + this.numRec > this.scores.length) {
				//Check to see if there are any scores left show them then on the next tick scroll to the next set of scores
				const remainingScores =
					this.scores.length - (this.curRec + this.numRec);
				if (remainingScores >= 1) {
					this.$vuetify.goTo(`#i${this.scores.length - 1}`);
					return;
				}

				if (!this.preventScroll) {
					//If there is nothing preventing the scroll
					this.nextScreen();
				} else {
					//Plays end of list animation
					this.runAnimation = true;
					this.setTimeout(() => {
						this.runAnimation = false;
					}, 810);
				}
				return;
			}

			//Move the screen where the last record shown becomes the first record.
			this.curRec += this.numRec - 1;
			this.$vuetify.goTo(`#i${this.curRec}`);
		},

		/**
		 * Loads scores from parent
		 */
		loadScores() {
			//Create a copy of the scores
			this.allScores = JSON.parse(JSON.stringify(this.inScores));

			//Load the scores and trim the name to a max of 20 characters
			lodash.forEach(this.allScores, (sc) => {
				sc.ShooterName = sc.ShooterName.trim().substring(0, 20);
				sc.TotalScore = sc.Score200;
			});

			//Remove scores of zero
			this.allScores = lodash.filter(this.allScores, (x) => x.TotalScore > 0);

			//Sort the scores by total score, then by name in alphabetical order
			this.allScores = lodash.orderBy(
				this.allScores,
				['TotalScore', 'ShooterName'],
				['desc', 'asc']
			);
		},
		setPaused(v) {
			this.paused = v;
			this.preventScroll = false;
		},
		startInterval() {
			if (this.itrvl) {
				this.clearInterval(this.itrvl);
				console.log('Double interval incident happened!!!');
			}
			this.itrvl = this.setInterval(this.tick, this.scrollMS);
		},
	},
	watch: {
		showSettings(v) {
			if (v) this.$emit('settingsOpened');
		},
		inScores: {
			handler() {
				this.loadScores();
				if (this.ScoreKey > 9999) this.ScoreKey = 0;
			},
			deep: true,
		},
	},
	mounted() {
		if (
			this.$store.state.LimitTopScores &&
			this.$store.state.LimitTopScores != -1
		)
			this.limitToTheTopXScores = this.$store.state.LimitTopScores;

		if (this.$store.state.ScrollTime)
			this.scrollMS = this.$store.state.ScrollTime;

		if (this.$store.state.EnableScrollDown)
			this.scrolling = this.$store.state.EnableScrollDown;

		this.clearInterval(this.itrvl);
		window.removeEventListener('keydown', this.keyboardShortcuts);

		this.loadScores();
		// this.scores = JSON.parse(JSON.stringify(this.allScores));

		Object.assign(this.scores, this.allScores); //Set first screen to show all scores

		this.setLimit();

		this.setTimeout(() => {
			try {
				this.$vuetify.goTo('#i0');
			} catch (e) {
				e;
			}
		}, 1000);

		this.startInterval();

		window.addEventListener('keydown', this.keyboardShortcuts);
	},
};
</script>

<style scoped>
.btmLine {
	border-bottom: 1px solid black;
}
.maxScore {
	font-weight: bold;
	color: #f00;
}
.shadow-drop-bottom {
	animation: shadow-drop-bottom 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1 both;
}
@keyframes shadow-drop-bottom {
	0% {
		box-shadow: 0 12px 20px -12px rgba(36, 89, 140, 0.75);
	}
	100% {
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}
.strikeout {
	text-decoration: line-through;
}
</style>
