<template>
	<div>
		<v-expand-transition>
			<div class="ticker-wrap" v-show="displayTicker"  style="z-index:9999 !important;">
				<div class="ticker">
					<div class="ticker__item" v-for="i in announcements" :key="i.updatedAt">
						{{ i.message | uppercase | stripPeriodsFromEnd }}
					</div>
				</div>
			</div>
		</v-expand-transition>
		<span
			:title="
				hasUpdate ? 'Server indicates that there are updates pending' : null
			"
			:class="{
				'generic-status': true,
				'yellow--text': hasUpdate,
				'transparent--text': !hasUpdate,
			}"
		>
			.
		</span>
	</div>
</template>

<script>
import axios from 'axios';
export default {
	name: 'Announcements',
	filters: {
		uppercase(value) {
			if (!value) return '';
			return value.toUpperCase();
		},
		stripPeriodsFromEnd(value) {
			return value.replace(/\.+$/, '');
		},
	},
	data() {
		return {
			displayTicker: false,
			announcements: [],
			errorCount: 0,
			hasUpdate: false,
			timeToScroll: 0,
			tickerCompleteCount: 0,
			tickerCompleteCountOverall: 0,
			scrollDoneInterval: null,
			toggleDisplayTimeout: null,
		};
	},
	mounted() {
		this.getAnnouncements();
		this.updateFontSize();
		this.$ServerBus.on('deleted-announcement', this.evtBusUpdate);
		this.$ServerBus.on('announcement-update', this.evtBusUpdate);
		this.$ServerBus.on('new-announcement', this.evtBusUpdate);
		window.addEventListener('keydown', this.keyboardShortcuts);

	},
	beforeDestroy() {
		this.$ServerBus.off('deleted-announcement', this.evtBusUpdate);
		this.$ServerBus.off('announcement-update', this.evtBusUpdate);
		this.$ServerBus.off('new-announcement', this.evtBusUpdate);
		window.removeEventListener('keydown', this.keyboardShortcuts);

	},
	watch: {
		displayTicker(v) {
			v;
		},
		'$vuetify.breakpoint': {
			deep: true,
			handler() {
				this.updateFontSize();
			},
		},
	},
	methods: {
		keyboardShortcuts(key) {
			if (window.enable_debug.keystrokes) console.log(key.key, key.keyCode);
			switch (key.key) {
				case 'e':
				case 'E':
					this.hasUpdate = !this.hasUpdate;
					break;
			}
		},
		updateFontSize() {
			let x = this.$vuetify.breakpoint.xsOnly
				? `14pt`
				: this.$vuetify.breakpoint.smAndDown
				? `20pt`
				: this.$vuetify.breakpoint.mdAndDown
				? '24pt'
				: this.$vuetify.breakpoint.lgAndDown
				? '30pt'
				: this.$vuetify.breakpoint.xlOnly
				? '34pt'
				: '24pt';
			document.querySelector(':root').style.setProperty('--font-size', x);
		},
		evtBusUpdate() {
			if (!this.displayTicker) {
				clearTimeout(this.toggleDisplayTimeout);
				this.toggleDisplayTimeout = null;
				this.getAnnouncements(true);
			}
			this.errorCount = 0;
			this.hasUpdate = true;
			console.log('Event bus signaled that there is an event bus update.');
		},
		getAnnouncements(overwriteErrorCount = false) {
			if (!overwriteErrorCount) {
				if (this.errorCount > 3) {
					console.warn('[Announcements] Error count announcements exceeded');
					return;
				}
			}
			let url = `https://altdata.shootpro.cloud/announcements/club/${this.$store.state.shoot.ClubID}/shoot/${this.$store.state.shoot.ShootID}`;

			if (window.enable_debug.announcements)
				console.log('Getting announcements', url);

			axios.get(url)
			.then(({ data }) => {
					this.$set(this, 'announcements', JSON.parse(JSON.stringify(data.filter(x=>x.type !== 'earnedYardage'))))
					this.displayTicker = this.announcements.length >= 1;
					this.errorCount = 0;
					this.setScrollSpeed(
						this.announcements.length,
						this.$store.state.Announcements.ScrollSpeed
					);
					this.$store.commit('SETYARDAGEPUNCHES',
						data.filter(x=>x.type === 'earnedYardage')
					)
					this.hasUpdate = false;
				})
				.catch((err) => {
					this.errorCount++;
					console.log(err);
					if (err.response) console.log(err.response);
					if (this.errorCount == 3)
						setTimeout(() => {
							this.errorCount = 0;
							//There was an error attempt to refetch the announcements after 30 seconds
							this.getAnnouncements(true);
						}, 30 * 1000);
				});
		},
		setScrollSpeed(numberOfAnnouncements, speed) {
			console.log(numberOfAnnouncements, speed)
			if (this.scrollDoneInterval) {
				this.tickerCompleteCount = 0;
				clearInterval(this.scrollDoneInterval);
				this.scrollDoneInterval = null;
			}
			let oneSpeed = 12;
			switch (speed) {
				case 1:
					oneSpeed = 20;
					break;
				case 2:
					oneSpeed = 14;
					break;
				case 3:
					oneSpeed = 10;
					break;
				case 5:
					oneSpeed = 40;
					break;
				case 4:
					oneSpeed = 60;
					break;
			}

			let scrollSpeed = oneSpeed * numberOfAnnouncements;

			document
				.querySelector(':root')
				.style
				.setProperty('--duration', `${scrollSpeed}s`);

			const goSpeed = scrollSpeed * 1000;
			if (goSpeed < 1 || !speed) {
				if (window.enable_debug.announcements) {
					console.log(
						'Announcements zero',
						scrollSpeed,
						scrollSpeed * 1000,
						scrollSpeed * 1000,
						speed
					);
				}
				//Announcements should be fetched on changes
				//setTimeout(this.getAnnouncements, 5000);

				return;
			}

			if (window.enable_debug.announcements)
				console.log('announcement:speed:', goSpeed);

			//SET TIME OUT INSTEAD OF SET INTERVAL
			this.scrollDoneInterval = setInterval(this.tickerRollComplete, goSpeed);
			this.timeToScroll = goSpeed;
		},
		tickerRollComplete() {
			if (window.enable_debug.announcements) console.log('complete');

			if (this.$store.state.Announcements.ToggleShowHideInterval != -1) {
				this.displayTicker = false;

				this.toggleDisplayTimeout = setTimeout(() => {
					if (this.hasUpdate) this.getAnnouncements();
					else this.displayTicker = true;
					clearTimeout(this.toggleDisplayTimeout);
					this.toggleDisplayTimeout = null;
				}, this.$store.state.Announcements.ToggleShowHideInterval);
			}else if(this.hasUpdate){
				this.getAnnouncements();
			}
			this.tickerCompleteCount++;
			this.tickerCompleteCountOverall++;
		},
	},
};
</script>

<style lang="css" scoped>
:root {
	--duration: 30s;
	--font-size: 16pt;
}

@-webkit-keyframes ticker {
	0% {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
		visibility: visible;
	}
	100% {
		-webkit-transform: translate3d(-100%, 0, 0);
		transform: translate3d(-100%, 0, 0);
	}
}
@keyframes ticker {
	0% {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
		visibility: visible;
	}
	100% {
		-webkit-transform: translate3d(-100%, 0, 0);
		transform: translate3d(-100%, 0, 0);
	}
}
.ticker-wrap {
	position: fixed;
	bottom: 0;
	width: 100%;
	overflow: hidden;
	height: 5rem;
	background-color: rgba(0, 0, 0, 0.9);
	padding-left: 100%;
	box-sizing: content-box;
}
.ticker-wrap .ticker {
	display: inline-block;
	height: 5rem;
	line-height: 5rem;
	white-space: nowrap;
	padding-right: 100%;
	box-sizing: content-box;
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
	-webkit-animation-timing-function: linear;
	animation-timing-function: linear;
	-webkit-animation-name: ticker;
	animation-name: ticker;
	-webkit-animation-duration: var(--duration);
	animation-duration: var(--duration);
}
.ticker-wrap .ticker__item {
	display: inline-block;
	padding: 0 10rem;
	font-size: var(--font-size);
	color: white;
}

body {
	padding-bottom: 5rem;
}

h1,
h2,
p {
	padding: 0 5%;
}

.generic-status {
	position: fixed;
	z-index: 999999;
	font-size: 40pt;
	bottom: 42px;
	right: 4px;
	cursor: default;
}
</style>
